@use 'mixins';

.ComponentHead {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: var(--smallPadding);
    border-bottom: 1px solid rgb(var(--sectionBorder));

    h3 {
        width: 100%;
    }

    &.margin-bottom {
        margin-bottom: var(--standardPadding);
    }

    :global(.theme-vp) & {
        h3 {
            strong {
                color: rgb(var(--textColor));
            }
        }
    }

    h3:only-child {
        padding-bottom: var(--smallPadding);
        padding-top: var(--smallPadding);

        :global(.theme-vp) & {
            text-transform: initial;

            strong {
                color: rgb(var(--textColor));
            }
        }
    }

    @include mixins.responsive(m, below) {
        margin-top: var(--standardPadding);
    }
}
