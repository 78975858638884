@use 'mixins';

.MatchList {
    .competition {
        margin-top: var(--largePadding);
        padding: var(--smallPadding) var(--smallPadding) var(--smallPadding) var(--standardPadding);
    }

    .partner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(var(--lighterInvertedBackground));
        padding: var(--mediumPadding);
        box-shadow: 2px 2px rgba(var(--invertedBackground), 0.1);

        span {
            @extend %caption-medium;

            color: rgb(var(--lighterTextColor));
            font-weight: var(--font-weight-regular);
            margin-right: var(--standardPadding);
        }

        img {
            margin-top: var(--tinyPadding);
        }
    }

    .partner-image {
        margin-top: var(--smallPadding);
    }

    .last-match {
        :global(.theme-vi) & {
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;

            @extend %section-shadow;

            &:first-of-type {
                display: none;
            }
        }
    }

    .first-match {
        :global(.theme-vi) & {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
        }
    }

    > li {
        &.even {
            :global(.theme-vi) & {
                background: rgb(var(--sectionBackAlternate));
            }
        }

        &.odd {
            background: rgb(var(--lightSectionBack));

            :global(.theme-vi) & {
                background: rgb(var(--sectionBack));
            }
        }

        &.match {
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            place-content: space-between;
            padding: var(--smallPadding) var(--smallPadding) var(--smallPadding) var(--standardPadding);
            cursor: pointer;

            > a {
                flex-grow: 1;
                text-decoration: none;
                color: inherit;

                &.time {
                    order: 1;
                    margin-top: var(--largePadding);

                    :global(.theme-vi) & {
                        flex-grow: initial;
                        margin-top: 0;
                    }
                }

                &.score {
                    order: 2;

                    :global(.theme-vi) & {
                        flex-grow: initial;
                        order: 3;

                        li:first-child {
                            margin-bottom: var(--tinyPadding);
                        }
                    }
                }
            }

            :global(.theme-vi) & {
                @extend %section-shadow;

                align-items: center;
            }
        }

        > button {
            margin-top: var(--standardPadding);
        }

        > h3 {
            @extend %heading-x-small;

            margin: var(--standardPadding) 0 var(--smallPadding);

            :global(.theme-vi) & {
                margin-top: var(--largePadding);
                margin-left: var(--standardPadding);
                margin-bottom: var(--standardPadding);
            }
        }
    }

    ul {
        line-height: 1.5em;

        li {
            vertical-align: top;

            &:first-child {
                margin-bottom: 2px;
            }
        }
    }

    .disclaimer-bar {
        padding-left: var(--standardPadding);
        padding-right: var(--standardPadding);

        :global(.theme-vi) & {
            position: relative;
            border-radius: 0 0 2px 2px;

            @extend %section-shadow;
        }
    }

    .competition {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-bottom: 0;
        border-bottom: 1px solid rgb(var(--sectionSeparationBorder));
    }

    .icon-bar {
        padding: var(--smallPadding) var(--smallPadding) var(--smallPadding) var(--standardPadding);
        color: rgb(var(--lighterTextColor));
        border-bottom: 1px solid rgb(var(--sectionSeparationBorder));

        .odds-header {
            float: right;
            letter-spacing: 1.5em;
            font-weight: 700;

            :global(.betting-disabled) & {
                display: none;
            }
        }

        :global(.f-icon) {
            &:first-child {
                margin-right: 6.1em;
            }
        }
    }

    .teams {
        flex: 1 100%;
        order: 3;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
            display: inline;
            overflow: hidden;
        }

        :global(.theme-vi) & {
            order: 2;
        }
    }
}

@include mixins.responsive(s, below) {
    .MatchList .desktop-only {
        display: none;
    }
}

@include mixins.responsive(m, below) {
    .MatchList {
        .icon-bar .odds-header {
            letter-spacing: 0.8em;
        }

        .f-icon {
            &.up-icon {
                top: 0;
            }

            &.down-icon {
                bottom: 0;
            }
        }

        .match {
            :global(.theme-vi) & {
                flex-wrap: wrap;

                > a {
                    flex: 1;

                    &.time {
                        order: 2;
                        padding-right: var(--largePadding);
                    }

                    &.score {
                        min-width: initial !important;
                    }
                }
            }
        }

        .partner {
            display: flex;
            justify-content: space-between;
            padding: var(--mediumPadding) var(--smallPadding);
        }

        .teams {
            :global(.theme-vi) & {
                flex: 1;
                order: 1;
            }
        }
    }
}
